import * as React from "react"
import { graphql } from "gatsby"
import { injectIntl, IntlContextConsumer, FormattedMessage } from "gatsby-plugin-intl"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"
import get from "lodash/get"
import bg1 from "../images/bg-blue-1.png"
import bg2 from "../images/bg-blue-2.png"
import jp1 from "../images/jp1.png"
import jp2 from "../images/jp2.png"
import bgWhite from "../images/bg-white.png"
import logoSmall from "../images/logo-small.png"
import logoBig from "../images/logo-big.png"
import footerLogo from "../images/k.png"
import closeIcon from "../images/close-icon.svg"
import {Helmet} from "react-helmet"
import Modal from "react-modal"

const languageNames = {
  "en-US": "In English",
  "fi": "Suomeksi",
}

// styles
const Main = styled.div`
  font-family: Poppins, Futura, Helvetica, Arial sans-serif;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  h1, h2, h3, h4, h5, h6 {
    color: #24303b;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-shadow: 0.1em 0.1em 0.1em rgba(0, 0, 0, 0.2);
  }
  h2 {
    font-size: 2em;
  }
  h3 {
    font-size: 1.8em;
  }
  h4 {
    font-size: 1.6em;
  }
  h5 {
    font-size: 1.4em;
  }
  h6 {
    font-size: 1.2em;
  }
`

const Hero = styled.div`
  background: #24303b url(${bg1}) no-repeat right top;
  background-size: auto 150%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Header = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  padding: 2em 3em;
  display: none;
  @media (min-width: 900px) {
    display: flex;
  }
`

const LogoSmall = styled.img`
  width: 30em;
`

const LogoBig = styled.img`
  margin: 16em 0 12em 0;
  width: 90%;
  max-width: 45em;
`

const Sections = styled.div`
  background: #24303b url(${bg2}) no-repeat left bottom;
  background-size: 100% auto;
  padding-bottom: 20em;
`

const SectionWrapper = styled.div`
  position: relative;
  margin-top: 4.5em;
  margin-bottom: 4.5em;
`

const FirstSectionWrapper = styled(SectionWrapper)`
  background: #dbdbd6 url(${bgWhite}) no-repeat right top;
  background-size: 100% auto;
  margin-top: 0;
`

const SectionContent = styled.div`
  max-width: 60em;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 2em;
  font-size: 1.2em;
  color: #4a77a7;
  font-weight: 300;
  background: #dbdbd6 url(${bgWhite}) no-repeat right top;
  background-size: 100% auto;
  white-space: pre-wrap;
  position: relative;
  h2:first-child {
    margin-top: 0;
  }
  @media (min-width: 900px) {
    padding: 4em;
  }
`

const FirstSectionContent = styled(SectionContent)`
  background: transparent;
  max-width: 45em;
`

const NaviWrapper = styled.div`

`


const NaviItem = styled.a`
  color: #fff;
  text-decoration: none;
  padding: 0em 1em;
  line-height: 1em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: 300;
  &:not(:last-child) {
    border-right: 1px solid #fff;
  }
`

const Quote = styled.div`
  color: #4a77a7;
  font-weight: 500;
  font-style: italic;
  font-size: 2em;
  margin: 1.5em auto;
  text-align: center;
  max-width: 22em;
  z-index: 10;
  position: relative;
  line-height: 1.2em;
  @media (min-width: 900px) {
    font-size: 3em;
  }
`

const FooterLogo = styled.img`
  margin: 4em auto;
  display: block;
  width: 90%;
  max-width: 20em;
`

const JP1 = styled.img`
  position: absolute;
  top: -27em;
  left: 50%;
  width: 60em;
  margin-left: -60em;
  display: none;
  @media (min-width: 900px) {
    display: block;
  }
`

const JP2 = styled.img`
  position: absolute;
  top: -18em;
  right: 50%;
  width: 50em;
  margin-right: -60em;
  display: none;
  @media (min-width: 900px) {
    display: block;
  }
`

const LinkButton = styled.a`
  background: #4a77a7;
  color: #ffffff;
  padding: 0.5em 1em;
  display: inline-block;
  margin-right: 0.5em;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background-color: #ffffff;
    color #4a77a7;
  }
`

const ModalTitle = styled.h2`
  font-size: 4em;
  font-weight: 200;
  margin-top: 0;
  margin-bottom: 0.25em;
  color: #4a77a7;
`

const CloseBtn = styled.a`
  position: absolute;
  top: 1em;
  right: 1em;
`

const CloseIcon = styled.img`
  width: 2em;
  height: 2em;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`

const Form = styled.form`
  padding: 2em;
  margin-top: 2em;
  border: 1px solid #c0c0c0;
  box-shadow: 0 0 0.5em rgba(0,0,0,0.1);
  background: #dbdbd6 url(${bgWhite}) no-repeat right top;
`

const Label = styled.label`
  margin-bottom: 1em;
  display: block;
`

const Input = styled.input`
  padding: 0.5em;
  border: 1px solid #c0c0c0;
  font-family: Poppins, Futura, Helvetica, Arial sans-serif;
  font-weight: 300;
  font-size: 1.2em;
  display: block;
  width: 100%;
  max-width: 30em;
  box-sizing: border-box;
`

const Textarea = styled.textarea`
  padding: 0.5em;
  border: 1px solid #c0c0c0;
  font-family: Poppins, Futura, Helvetica, Arial sans-serif;
  font-weight: 300;
  font-size: 1.2em;
  display: block;
  width: 100%;
  max-width: 30em;
  box-sizing: border-box;
  resize: none;
  height: 6em;
`

const Checkbox = styled.input`
  margin-right: 0.8em;
`

const FileInput = styled.input`
  display: block;
  font-size: 1.2em;
  font-family: Poppins, Futura, Helvetica, Arial sans-serif;
  font-weight: 300;
  margin-top: 0.5em;
`

const Submit = styled.input`
  background: #4a77a7;
  color: #ffffff;
  padding: 0.5em 1em;
  display: inline-block;
  margin-right: 0.5em;
  cursor: pointer;
  text-decoration: none;
  border: none;
  font-family: Poppins, Futura, Helvetica, Arial sans-serif;
  font-size: 1.2em;
  &:hover {
    background-color: #ffffff;
    color #4a77a7;
  }
`

const ModalImage = styled.img`
  display: block;
  width: 100%;
  margin: 1em 0;
`

const modalStyle = {
  overlay: {
    zIndex: 11,
    background: "rgba(74,119,167,0.5)"
  },
  content: {
    fontFamily: "Poppins, Futura, Helvetica, Arial sans-serif",
    zIndex: 12,
    background: `#ffffff`,
    padding: "2em",
    boxSizing: "border-box",
    maxWidth: "60em",
    marginLeft: "auto",
    marginRight: "auto",
    whiteSpace: "pre-wrap",
  }
}

const Hidden = styled.div`
  visibility: hidden;
  height: 0;
  overflow: hidden;
`

const PrivacyLink = styled.a`
  color: #4a77a7;
  margin-bottom: 1em;
  display: block;
  &:hover {
    color: #000000;
  }
`

const CloseButton = ({onClick}) => {
  return <CloseBtn href="#" onClick={(e) => {e.preventDefault(); onClick();}}>
    <CloseIcon src={closeIcon} alt="Close" />
  </CloseBtn>
}


const ContactForm = injectIntl(({intl, additionalProps, setPrivacyOpen}) => {
  return <Form netlify name="contact" method="POST" data-netlify="true" {...additionalProps} enctype="multipart/form-data">
    <input type="hidden" name="form-name" value="contact" />
    <Label>
      <FormattedMessage id="Name" />
      <Input type="text" name="name" />
    </Label>
    <Label>
      <FormattedMessage id="E-mail" />
      <Input type="email" name="email" />
    </Label>
    <Label>
      <FormattedMessage id="Phone" />
      <Input type="tel" name="phone" />
    </Label>
    <Label>
      <FormattedMessage id="Message" />
      <Textarea name="message" />
    </Label>
    <Label>
      <FormattedMessage id="LinkedIn or CV link" />
      <Input type="text" name="cv-link" />
    </Label>
    {/*<Label>
      <FormattedMessage id="Attachment" />
      <FileInput type="file" name="attachment" />
    </Label>*/}
    <Label>
      <Checkbox type="checkbox" name="contact-agreement" value="yes" />
      <FormattedMessage id="I agree to be contacted via e-mail or phone" />
    </Label>
    {setPrivacyOpen &&
      <PrivacyLink href="#" onClick={(e) => {e.preventDefault(); setPrivacyOpen(true)}}>
        <FormattedMessage id="By submitting this form, you agree to our Privacy Policy" />
      </PrivacyLink>
    }
    <Submit type="submit" value={intl.formatMessage({id: "Send"})} />
  </Form>
})


const Navi = ({sections, intl}) => {
  return <NaviWrapper>
    {/*<NaviItem href="#">{intl.formatMessage({id: "home"})}</NaviItem>*/}
    {sections.map(({node}, i) =>
      <NaviItem key={node.identifier} href={"#" + node.identifier}>{node.title}</NaviItem>
    )}
    <IntlContextConsumer>
      {({languages, language: currentLocale}) =>
        languages.filter((l) => currentLocale !== l).map(language => (
          <NaviItem
            key={language}
            href={"/" + language}
          >
            {languageNames[language]}
          </NaviItem>
        ))
      }
    </IntlContextConsumer>
  </NaviWrapper>
}

const Section = ({section, index, last, setCvOpen, setCaseOpen}) => {
  const title = get(section, "title")
  const content = get(section, "content")
  const identifier = get(section, "identifier")
  const quote = get(section, "quote.quote")
  const cvButtonText = get(section, "cvButtonText")
  const caseButtonText = get(section, "caseButtonText")
  const WrapperElement = (index === 0) ? FirstSectionWrapper : SectionWrapper
  const ContentElement = (index === 0) ? FirstSectionContent : SectionContent
  return <>
    <WrapperElement index={index} id={identifier}>
      {index === 1 && <JP1 src={jp1} />}
      <ContentElement>
        {index > 0 && <h2>{title}</h2>}
        {renderRichText(content)}
        {cvButtonText && <LinkButton onClick={() => setCvOpen(true)}>{cvButtonText}</LinkButton>}
        {caseButtonText && <LinkButton onClick={() => setCaseOpen(true)}>{caseButtonText}</LinkButton>}
      </ContentElement>
      {last && <FooterLogo src={footerLogo} />}
      {last && <JP2 src={jp2} />}
    </WrapperElement>
    {quote && <Quote>"{quote}"</Quote>}
  </>
}

// markup
const IndexPage = ({data, intl}) => {
  const sections = get(data, 'allContentfulSection.edges')
  const popups = get(data, 'allContentfulPopup.edges')

  const cvContent = popups.filter(({node}) => node.type === "CV")[0]
  const caseContent = popups.filter(({node}) => node.type === "Case")[0]
  const privacyContent = popups.filter(({node}) => node.type === "Privacy policy")[0]

  const [isCvOpen, setCvOpen] = React.useState(false)
  const closeCv = () => setCvOpen(false)

  const [isCaseOpen, setCaseOpen] = React.useState(false)
  const closeCase = () => setCaseOpen(false)

  const [isPrivacyOpen, setPrivacyOpen] = React.useState(false)
  const closePrivacy = () => setPrivacyOpen(false)

  return (
    <Main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.site.siteMetadata.title}</title>
      </Helmet>
      <Hero>
        <Header>
          <LogoSmall src={logoSmall} alt="" />
          <Navi sections={sections} intl={intl} />
        </Header>
        <LogoBig src={logoBig} alt="" />
      </Hero>
      <Sections>
        {sections.map(({node}, index) => {
          return <Section
            key={node.identifier}
            section={node}
            index={index}
            setCvOpen={setCvOpen}
            setCaseOpen={setCaseOpen}
            last={index === (sections.length - 1)} />
        })}
      </Sections>

      <Modal
          isOpen={isCvOpen}
          onRequestClose={closeCv}
          style={modalStyle}
          ariaHideApp={false}
        >
          <ModalTitle>{cvContent.node.title}</ModalTitle>
          {cvContent.node.image && <ModalImage src={cvContent.node.image.fixed.src} />}
          {renderRichText(cvContent.node.content)}
          <CloseButton onClick={closeCv} />
      </Modal>

      <Modal
          isOpen={isCaseOpen}
          onRequestClose={closeCase}
          style={modalStyle}
          ariaHideApp={false}
        >
          <ModalTitle>{caseContent.node.title}</ModalTitle>
          {caseContent.node.image && <ModalImage src={caseContent.node.image.fixed.src} />}
          {renderRichText(caseContent.node.content)}
          <ContactForm setPrivacyOpen={setPrivacyOpen} />
          <CloseButton onClick={closeCase} />
      </Modal>

      <Modal
          isOpen={isPrivacyOpen}
          onRequestClose={closePrivacy}
          style={modalStyle}
          ariaHideApp={false}
        >
          <ModalTitle>{privacyContent.node.title}</ModalTitle>
          {privacyContent.node.image && <ModalImage src={privacyContent.node.image.fixed.src} />}
          {renderRichText(privacyContent.node.content)}
          <CloseButton onClick={closePrivacy} />
      </Modal>

      <Hidden>
        <ContactForm additionalProps={{"netlify-honeypot": "bot-field", "hidden": true}} />
      </Hidden>
    </Main>
  )
}

export default injectIntl(IndexPage)

export const pageQuery = graphql`
  query IndexQuery($locale: String) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulSection(filter: { node_locale: { eq: $locale } } sort: { fields: [order], order: ASC }) {
      edges {
        node {
          identifier
          title
          content {
            raw
          }
          quote {
            quote
          }
          order
          cvButtonText
          caseButtonText
        }
      }
    }
    allContentfulPopup(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          title
          content {
            raw
          }
          type
          image {
            ... on ContentfulAsset {
              fixed(width: 1920) {
                src
              }
            }
          }
        }
      }
    }
  }
`
